// extracted by mini-css-extract-plugin
export var headMarketplace = "marketplace-module--headMarketplace--3BHwr";
export var headMarketplaceWrapper = "marketplace-module--headMarketplaceWrapper--23Ymf";
export var headMarketplaceTitle = "marketplace-module--headMarketplaceTitle--UmG0Z";
export var headMarketplaceSubtitle = "marketplace-module--headMarketplaceSubtitle--2tDa-";
export var sliderContainer = "marketplace-module--sliderContainer--3a9Wo";
export var sliderTitle = "marketplace-module--sliderTitle--2H5ax";
export var categorySelector = "marketplace-module--categorySelector--16xWM";
export var categorySelectorTitle = "marketplace-module--categorySelectorTitle--2CQXc";
export var categorySelectorCategoriesContainer = "marketplace-module--categorySelectorCategoriesContainer--2duQW";
export var categoryLink = "marketplace-module--categoryLink--1z28s";
export var category = "marketplace-module--category--1Ifpc";
export var categoryTitle = "marketplace-module--categoryTitle--KMLqr";
export var categoryTitleScrollTo = "marketplace-module--categoryTitleScrollTo--2oER6";
export var categoryTitleName = "marketplace-module--categoryTitleName--3iIH_";
export var categoryOpenAll = "marketplace-module--categoryOpenAll--1ejcH";
export var wrapper = "marketplace-module--wrapper--1EDYd";
export var categoryListWrapper = "marketplace-module--categoryListWrapper--yiEPj";
export var supplierLink = "marketplace-module--supplierLink--Lb_0x";
export var supplierItem = "marketplace-module--supplierItem--1JYVj";
export var supplierHeader = "marketplace-module--supplierHeader--15Grp";
export var logo = "marketplace-module--logo--mNkwS";
export var name = "marketplace-module--name--19a3A";
export var partnerInfo = "marketplace-module--partnerInfo--1B9ZH";
export var supplierAction = "marketplace-module--supplierAction--jDvXR";
export var noSwiper = "marketplace-module--noSwiper--23eA-";
export var button = "marketplace-module--button--2GpTi";
export var formBlock = "marketplace-module--formBlock--39ELn";
export var formContainer = "marketplace-module--formContainer--3_z2l";
export var formTitle = "marketplace-module--formTitle--3SUKQ";
export var formSubtitle = "marketplace-module--formSubtitle--1xc4v";
export var formScrollTo = "marketplace-module--formScrollTo--1kL_1";
export var formText = "marketplace-module--formText--3XaRc";