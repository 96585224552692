import PropTypes from "prop-types";
import React from "react";

import { Box, Card } from "@material-ui/core";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import subtract from '../../../../assets/svg/subtract.svg';
import * as styles from '../../marketplace.module.css';

SwiperCore.use([Autoplay, Pagination]);

export default function MarketplaceSlider({ suppliers }) {
    const elCard = (slug, title, image) => {
        return (
            <a
                className="swiper-slide-link"
                href={`${process.env.GATSBY_ROOT_URL}/marketplace/${slug}`}
                key={`slide_${slug}`}
                rel="noreferrer noreferrer"
            >
                <Card
                    sx={{
                        width: "100%",
                        height: "100%",
                        transition: "0.3s",
                        borderRadius: "16px",
                        overflow: "hidden",
                        transform: "translateZ(0)", // HACK: https://stackoverflow.com/questions/49066011/overflow-hidden-with-border-radius-not-working-on-safari
                        boxShadow: 'none',
                        position: 'relative',
                        '-webkit-transform': 'translate3d(0, 0, 0)',

                        '& img': {
                            objectFit: 'contain !important'
                        }
                    }}
                >
                    <Box
                        className="swiper-slide-title"
                        sx={{
                            maxWidth: '124px',
                            width: '124px',
                            maxHeight: '100px',
                            height: '100px',
                            margin: '0 auto',
                            display: 'block',
                            backgroundImage: `url("${image.localFile.childImageSharp.gatsbyImageData.images.fallback.src}")`,
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat'
                        }}
                    />
                    <Box
                        className="swiper-slide-title"
                        sx={{
                            fontSize: '13px',
                            lineHeight: '16px',
                            fontWeight: '400',
                            textAlign: 'center',
                            boxSizing: 'border-box',
                            padding: '4px 16px 16px'
                        }}
                    >
                        {title}
                    </Box>
                    <div className={styles.supplierAction} style={{ top: 0 }}>
                        <img alt="Иконка акции" src={subtract} /> Акция
                    </div>
                </Card>
            </a>
        );
    };

    return (
        <Box>
            <Box
                sx={{
                    "& .swiper-container": {
                        pb: '40px',
                    },
                    "& .swiper-slide-link": {
                        display: "flex",
                        textDecoration: "none",
                        width: "100%",
                        height: '153px',
                        mr: '27px',

                        "&:hover .swiper-slide-title": {
                            color: 'rgba(255, 120, 15, 1)',
                            textDecoration: 'underline'
                        }
                    },
                    "& .swiper-container-horizontal > .swiper-pagination-bullets": {
                        bottom: 0,
                    }
                }}
            >
                {suppliers?.length && suppliers.length > 4 && (
                    <Swiper
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            1280: {
                                slidesPerView: 4
                            }
                        }}
                        centeredSlide={false}
                        pagination={{
                            clickable: true,
                        }}
                        roundLengths
                        slidesPerView={2}
                        spaceBetween={24}
                    >
                        {suppliers?.map((supplier) => (
                            <SwiperSlide key={supplier.slug}>
                                {elCard(supplier.slug, supplier.name, supplier.img)}
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
                {suppliers?.length && suppliers.length <= 4 && (
                    <div className={styles.noSwiper}>
                        {suppliers?.map((supplier) => (
                            elCard(supplier.slug, supplier.name, supplier.img)
                        ))}
                    </div>
                )}
            </Box>
        </Box>
    );
}

MarketplaceSlider.propTypes = {
    suppliers: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string,
            name: PropTypes.string,
            img: PropTypes.object,
        }),
    ),
};